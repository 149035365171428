/*Third Party Libraries*/
@import url("./third-party-libraries/tailwind.css");
@import url("./third-party-libraries/surveyjs.css");
@import url("./third-party-libraries/ckeditor.css");

/*External Font Families*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*Design Elements*/
@import url("./design-elements/base-variables.css");
@import url("./design-elements/common-tokens.css");
@import url("./design-elements/component-tokens.css");

/*Custom Styles*/
@import url("./base-styles.css");
