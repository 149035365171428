/* Note that we do not want to include this in Tailwind layers as they should
only be used for CKEditor and not as Tailwind theme classes*/
.ck-editor__main,
.ck-rounded-corners {
  @apply !h-full !w-full;
}

.ck-editor__editable_inline {
  @apply !px-3;
}
