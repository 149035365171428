@layer utilities {
  /*ANCHOR: SVGs, Images, & Image Containers*/
  .pmg-logo {
    @apply aspect-[718/993];
  }

  .society-image {
    @apply rounded-sm border-2 border-society;
  }

  /*ANCHOR: Sidebars*/
  .sidebar-selector-svg {
    @apply stroke-neutral-mid-800 cursor-pointer hover:stroke-society;
  }

  .sidebar-selector-image {
    @apply society-image cursor-pointer hover:shadow-md hover:shadow-society;
  }

  .sidebar-menu-container {
    @apply container-sidebar pt-4;
  }

  .sidebar-menu-selector {
    @apply rounded p-1 hover:bg-society-50;
  }

  .sidebar-menu-svg {
    @apply stroke-neutral-mid-800 group-hover:stroke-society;
  }

  .sidebar-menu-title {
    @apply text-neutral-mid-800 group-hover:text-society;
  }

  .sidebar-menu-submenu-title {
    @apply text-neutral-mid-500 hover:text-society;
  }

  .sidebar-menu-submenu-vert {
    @apply bg-neutral-mid-500 group-hover:bg-society;
  }

  .sidebar-menu-selector-svg {
    @apply stroke-neutral-mid-800 hover:stroke-society hover:cursor-pointer;
  }

  /*ANCHOR: New App Table*/
  .apptable-filters-selectmenu-button-container {
    @apply focus:border-0 focus:ring-0 focus-visible:outline-none items-center;
  }

  .apptable-filters-selectmenu-button-title {
    @apply text-filter-label group-hover:text-neutral-mid;
  }

  .apptable-filters-selectmenu-button-count {
    @apply rounded bg-neutral-light-600 px-0.25 py-0 text-xs font-semibold tabular-nums text-neutral-mid-700;
  }

  .apptable-filters-selectmenu-button-icon {
    @apply stroke-neutral-mid-400 group-hover:stroke-neutral-mid-500;
  }

  .apptable-filters-selectmenu-popover-container {
    @apply rounded-md bg-neutral-light p-2 shadow-2xl ring-1 ring-neutral-dark ring-opacity-5 focus:outline-none;
  }

  .apptable-filters-selectmenu-dropdown-container {
    @apply rounded pl-2 focus:outline-none;
  }

  .apptable-table-base {
    @apply border-separate border-spacing-0 rounded-t-lg border-neutral-light-550 border bg-neutral-mid-100 shadow-xl;
  }

  /* TODO: STYLING Rounded bottom corners are not working on tables without pagination */
  .apptable-table-base-roundbottom {
    @apply apptable-table-base rounded-b-xl;
  }

  .apptable-table-header {
    @apply text-left;
  }

  .apptable-table-header-cell-general {
    @apply p-1.5 text-left text-xs font-semibold text-neutral-mid-700 border-b border-neutral-light-700;
  }

  .apptable-table-header-cell-centered {
    @apply apptable-table-header-cell-general px-0 text-center;
  }

  .apptable-table-row-general {
    @apply bg-neutral-light border-b border-neutral-light-700 text-xs text-neutral-mid;
  }

  .apptable-table-row-interactable {
    @apply apptable-table-row-general hover:bg-society-50 hover:cursor-pointer;
  }

  .apptable-table-cell-general {
    @apply p-2;
  }

  .apptable-table-cell-edit {
    @apply py-2;
  }

  .apptable-table-cell-delete {
    @apply py-2;
  }

  .profile-id-badge {
    @apply inline-flex whitespace-nowrap items-center border-2 border-society text-society lg:px-6 px-4 text-sm font-semibold rounded-3xl;
  }

  .apptable-table-cell-checkbox {
    @apply h-2 w-4 rounded border-neutral-mid-300;
  }
  .apptable-table-cell-checkbox-hide-disabled-not-checked:disabled:not(
      :checked
    ) {
    display: none;
  }
  .apptable-table-cell-badge-danger {
    @apply bg-status-danger-light rounded-full py-0.5 px-1 text-status-danger font-semibold;
  }

  .apptable-table-cell-badge-success {
    @apply bg-status-success-light rounded-full py-0.5 px-1 text-status-success font-semibold;
  }

  .apptable-table-cell-badge-warning {
    @apply bg-status-warning-light rounded-full py-0.5 px-1 text-status-warning-tint font-semibold;
  }

  .apptable-table-cell-badge-neutral {
    @apply bg-neutral-light-100 rounded-full py-0.5 px-1 text-neutral-mid-500 font-semibold;
  }

  .apptable-table-cell-interactable {
    @apply apptable-table-cell-general hover:underline;
  }

  .apptable-pagination-container {
    @apply p-1.5 text-xs text-neutral-mid-700 rounded-b-xl border-l border-r border-b border-neutral-light-550 bg-neutral-mid-100 shadow-xl;
  }

  .apptable-pagination-display {
    @apply text-xs text-neutral-mid-700;
  }

  .apptable-pagination-link-step-container {
    @apply link-undecorated p-0.25 text-sm text-neutral-mid-700 border border-neutral-mid-200 hover:bg-neutral-mid-200 hover:cursor-pointer first:rounded-l-md last:rounded-r-md;
  }

  .apptable-pagination-link-step-svg {
    @apply stroke-neutral-mid-700;
  }

  .apptable-pagination-link-page {
    @apply link-undecorated text-sm font-bold text-neutral-mid-700 p-0.25 border-t border-b border-neutral-mid-200 hover:cursor-pointer;
  }

  .apptable-pagination-link-page--unselected {
    @apply apptable-pagination-link-page hover:bg-neutral-mid-200;
  }

  .apptable-popover-menu-button {
    @apply cursor-pointer;
  }

  .apptable-popover-menu-container {
    @apply rounded-md bg-neutral-light shadow-lg ring-1;
  }

  .apptable-popover-menu-item {
    @apply text-sm;
  }

  .apptable-popover-menu-item-enabled {
    @apply apptable-popover-menu-item hover:bg-neutral-light-650 cursor-pointer;
  }

  .apptable-popover-menu-item-disabled {
    @apply apptable-popover-menu-item text-neutral-mid-250 cursor-not-allowed;
  }

  /*TODO: Using the important (!) symbol here is not ideal.
  However, Tailwind seems to apply classes in an arbitrary order here, which breaks the expected class cascade.*/
  .apptable-pagination-link-page--selected {
    @apply apptable-pagination-link-page bg-primary !text-neutral-light !border-primary;
  }

  /*ANCHOR: New App Modal*/
  .appmodal-body-background {
    @apply bg-neutral-dark;
  }

  .appmodal-panel-container {
    @apply rounded bg-neutral-light p-2 text-left align-middle shadow-xl;
  }

  .appmodal-panel-title {
    @apply text-xl font-semibold leading-relaxed text-neutral-dark mb-2;
  }

  /*ANCHOR: New App Drodown Menu*/
  .appdropdown-button-container {
    @apply input-light border-2;
  }

  .appdropdown-options-container {
    @apply rounded bg-neutral-light text-base shadow-lg ring-0 border border-neutral-mid-200;
  }

  .appdropdown-options-option {
    @apply select-none cursor-default text-sm pt-0.5 pb-0.5 hover:bg-neutral-light-600;
  }

  .appdropdown-options-option-unselected {
    @apply appdropdown-options-option text-neutral-mid;
  }

  .appdropdown-options-option-selected {
    @apply appdropdown-options-option bg-neutral-light-550 text-neutral-mid-900;
  }

  /*ANCHOR: Tabs*/
  .tabs-container {
    @apply bg-neutral-light-550 rounded-t border-t border-r border-l border-neutral-light-550 shadow-xl;
  }

  .tabs-tab {
    @apply rounded-t-sm text-sm text-neutral-mid-600 text-center hover:bg-neutral-light-650 hover:cursor-pointer;
  }

  .tabs-tab-selected {
    @apply tabs-tab bg-neutral-light;
  }

  .tabs-tab-unselected {
    @apply tabs-tab;
  }

  .tabs-content-container-outer {
    @apply bg-neutral-light border border-neutral-light-550 rounded-b shadow-xl p-0.75;
  }

  .tabs-content-container-inner {
    @apply bg-neutral-light-tint rounded-lg px-4.5 pt-4.5 pb-2;
  }

  .small-content-card {
    @apply bg-neutral-light border border-neutral-mid-100 rounded-lg shadow p-1 text-center mb-1;
  }

  /*ANCHOR: Day Selector*/
  .dayselector-root-container {
    @apply border-t border-b border-neutral-mid-200 text-neutral-mid pl-5 pr-5;
  }

  .dayselector-selector-container {
    @apply first:border-l first:border-l-neutral-mid-200 last:border-r last:border-r-neutral-mid-200 hover:cursor-pointer;
  }

  .dayselector-selector-daynum-container {
    @apply rounded-full border-2 group-hover:bg-neutral-mid-200;
  }

  .dayselector-selector-daynum-container-selected {
    @apply dayselector-selector-daynum-container bg-primary border-primary text-neutral-light group-hover:bg-primary;
  }

  .dayselector-selector-daynum-container-unselected {
    @apply dayselector-selector-daynum-container border-neutral-mid-200 text-neutral-mid;
  }

  .dayselector-selector-date-container {
    @apply font-medium;
  }

  .dayselector-table-filter {
    @apply !rounded !border-neutral-mid-200 !text-neutral-mid-600 !font-medium;
  }

  .dayselector-table-filter-header {
    @apply text-filter-label pl-1;
  }

  .dayselector-selector-divider-svg {
    @apply text-neutral-mid-200;
  }

  /*TODO: ui-disabled and ui-not-disabled should be usable without needing to swap classes out, but they are not working on either container or toggle*/
  /*ANCHOR: New App Switch*/

  .appswitch-container {
    @apply rounded-full cursor-pointer transition-colors duration-200 ease-in-out ring-0 focus:ring-0 ui-checked:bg-primary ui-not-checked:bg-neutral-mid-200;
  }

  .appswitch-container--disabled {
    @apply !bg-neutral-light-600;
  }

  /*TODO: ui-checked and ui-not-checked are also not working on the toggle along with ui-disabled and ui-not-disabled*/
  .appswitch-toggle-span {
    @apply pointer-events-none shadow transition duration-200 ease-in-out;
  }

  .appswitch-toggle-span-checked {
    @apply appswitch-toggle-span bg-neutral-light;
  }

  .appswitch-toggle-span-notchecked {
    @apply appswitch-toggle-span bg-neutral-mid;
  }

  .appswitch-toggle-span--disabled {
    @apply !bg-neutral-mid-250;
  }

  /*ANCHOR: New App Combobox*/
  .appcombo-button-container {
    @apply bg-neutral-light-550 stroke-neutral-mid-300 text-neutral-mid-300 hover:bg-neutral-light-700;
  }

  .appcombo-options-container {
    @apply rounded-md bg-neutral-light text-base text-neutral-mid-200 shadow-lg ring-0;
  }

  .appcombo-options-option {
    @apply cursor-default select-none text-sm text-neutral-mid p-0.5;
  }

  .appcombo-options-option-general {
    @apply appcombo-options-option ui-active:bg-primary ui-active:text-neutral-light;
  }

  .appcombo-options-option-add {
    @apply appcombo-options-option !text-base;
  }

  .powerbar-container {
    @apply bg-neutral-light border-b-4 border-society;
  }

  .accordion-row {
    @apply rounded-md bg-neutral-light shadow-lg my-2;
  }

  .powerbar-container-general {
    @apply powerbar-container;
  }

  .powerbar-container-animated {
    @apply powerbar-container animate-pulse;
  }

  .colorpicker-swatch {
    @apply shadow-md cursor-pointer rounded-lg border-2 border-neutral-mid-200;
  }

  .colorpicker-popover {
    @apply shadow-md rounded;
  }

  /*ANCHOR: Profile Feed*/
  .profile-feed-person-link {
    @apply font-semibold no-underline text-neutral-mid-750 hover:text-neutral-mid-550;
  }

  .profile-feed-date {
    @apply whitespace-nowrap text-neutral-mid-500;
  }

  /*ANCHOR: Responsiveness for mustache files*/
  .mustache-file-profile-overview-responsive-width {
    @apply w-full;
  }

  .mustache-file-profile-overview-responsive-display {
    @apply flex flex-col gap-1 ml-2;
  }

  @media (min-width: 1400px) {
    .mustache-file-profile-overview-responsive-width {
      @apply w-1/2;
    }
  }

  @media (min-width: 1400px) {
    .mustache-file-profile-overview-responsive-display {
      @apply flex flex-row;
    }
  }

  /* 
  "relative cursor-default select-none py-2 pl-8 pr-4",
  active
  ? "bg-indigo-600 text-neutral-light"
  : "text-neutral-mid-900 */
}
