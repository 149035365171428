@layer components {
  /*ANCHOR: Containers*/
  .container-primary {
    @apply p-4 bg-neutral-light;
  }

  .container-secondary {
    @apply p-4 bg-neutral-light-tint;
  }

  .container-sidebar {
    @apply border-r border-neutral-mid-50 bg-neutral-light pt-2 pb-3;
  }

  .container-inputbank-light {
    @apply bg-neutral-light shadow rounded-lg;
  }

  /*ANCHOR: Typography*/
  .text-title {
    @apply text-2xl font-bold text-neutral-mid-700;
  }

  .text-subheader {
    @apply text-xl font-semibold text-neutral-mid-600;
  }

  .text-subtle-semibold {
    @apply text-sm text-neutral-mid font-semibold;
  }

  .text-subtle {
    @apply text-sm text-neutral-mid;
  }

  .text-filter-label {
    @apply text-sm !text-neutral-mid-600 font-medium;
  }

  .text-header {
    @apply text-xl font-semibold mb-1 text-neutral-mid-750;
  }

  .text-body-header {
    @apply font-medium text-base text-neutral-mid-600;
  }

  .text-body-light {
    @apply font-normal text-base text-neutral-mid-600;
  }

  .text-danger-small {
    @apply text-sm text-status-danger;
  }

  /*ANCHOR: Interactable*/
  .link-undecorated {
    @apply text-neutral-mid no-underline;
  }

  /*ANCHOR: Inputs*/
  .input-light {
    @apply p-0.75 rounded text-sm bg-neutral-light text-neutral-mid-700 border-neutral-mid-200 placeholder:text-neutral-mid-200 focus:border-neutral-mid-300 focus:ring-neutral-mid-300 disabled:bg-neutral-light-550 disabled:text-neutral-light-900;
  }

  .input-mid {
    @apply p-0.75 rounded text-sm bg-neutral-light-tint text-neutral-mid-700 border-neutral-mid-200 placeholder:text-neutral-mid-200 focus:border-neutral-mid-300 focus:ring-neutral-mid-300 disabled:bg-neutral-light-tint-550 disabled:text-neutral-light-900;
  }

  /*ANCHOR: Buttons*/
  .button-regular {
    @apply rounded-md border-2 border-solid px-1.5 py-1 text-sm font-medium disabled:opacity-60;
  }

  .button-small {
    @apply rounded-md border-2 border-solid px-0.75 py-0.5 text-xs font-medium disabled:opacity-60;
  }

  .button-regular-general-filled {
    @apply button-regular border-0 bg-society-550 text-neutral-light stroke-neutral-light hover:bg-society-500 disabled:hover:bg-society-550;
  }

  .button-regular-general-unfilled {
    @apply button-regular border-0 bg-transparent text-society-550 stroke-society-550 hover:bg-transparent hover:text-society-500 hover:stroke-society-500 disabled:hover:bg-transparent;
  }

  .button-regular-neutral-filled {
    @apply button-regular border-0 bg-neutral-light-700 stroke-neutral-light-700 text-neutral-mid-750 hover:bg-neutral-light-800 disabled:hover:bg-neutral-light-700;
  }

  .button-regular-neutral-unfilled {
    @apply button-regular border-0 bg-transparent stroke-neutral-mid-750 text-neutral-mid-750 hover:bg-transparent hover:text-neutral-mid-650 hover:stroke-neutral-mid-650 disabled:hover:bg-transparent;
  }

  .button-regular-destructive-filled {
    @apply button-regular border-0 bg-status-danger text-neutral-light hover:bg-status-danger-tint disabled:bg-status-danger-light disabled:hover:bg-status-danger;
  }

  .button-regular-add-filled {
    @apply button-regular border-0 bg-society-550 text-neutral-light hover:bg-society-500 disabled:hover:bg-society-550;
  }

  .button-regular-add-unfilled {
    @apply button-regular border-0 bg-transparent text-society-550 hover:text-society-500 hover:cursor-pointer hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-regular-warning-filled {
    @apply button-regular border-0 bg-status-warning text-neutral-mid-900 hover:bg-status-warning-tint disabled:hover:bg-status-warning;
  }

  .button-small-general-filled {
    @apply button-small border-0 bg-society-550 text-neutral-light stroke-neutral-light hover:bg-society-500 disabled:hover:bg-society-550;
  }

  .button-small-neutral-filled {
    @apply button-small border-0 bg-neutral-light-700 text-neutral-mid-750 hover:bg-neutral-light-800 disabled:hover:bg-neutral-light-700;
  }

  .button-small-destructive-filled {
    @apply button-small border-0 bg-status-danger text-neutral-light hover:bg-status-danger-tint disabled:hover:bg-status-danger;
  }

  .button-small-destructive-unfilled {
    @apply button-small border-0 bg-transparent text-status-danger hover:bg-status-danger hover:text-neutral-light disabled:hover:bg-transparent;
  }

  .button-small-add-unfilled {
    @apply button-small border-0 bg-transparent text-society-550 hover:text-society-500 hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-small-add-filled {
    @apply button-small border-0 bg-society-150 text-society-550 hover:bg-society-100 disabled:hover:bg-society-150;
  }

  .button-regular-success-filled {
    @apply button-regular border-0 bg-status-success text-neutral-light hover:bg-status-success-tint disabled:hover:bg-status-success;
  }

  .button-small-success-filled {
    @apply button-small border-0 bg-status-success text-neutral-light hover:bg-status-success-tint disabled:hover:bg-status-success;
  }

  .button-small-warning-filled {
    @apply button-small border-0 bg-status-warning text-neutral-mid-600 hover:bg-status-warning-tint disabled:hover:bg-status-warning;
  }

  /*ANCHOR: Profile Avatar*/
  .profile-avatar-container {
    @apply shadow-sm rounded-full bg-cover bg-center bg-no-repeat text-sm font-semibold;
  }

  /*ANCHOR: Datepicker*/
  .datepicker-shadow {
    @apply opacity-25 block appmodal-body-background bg-neutral-dark/100 fixed inset-0 z-10;
  }
  .datepicker-flex {
    @apply flex flex-1 items-center;
  }
  .datepicker-chevron-icon {
    @apply w-2 h-2;
  }
  .datepicker-calendar-day-select-base {
    @apply bg-society text-neutral-light font-medium;
  }
  .chevron-icon {
    @apply w-6 h-6;
  }
  .footer-button-prefix {
    @apply w-full mr-3 transition-all duration-300 text-neutral-light font-medium border px-4 py-2 text-sm rounded-md focus:ring-2 focus:ring-offset-2 /*hover:bg-gray-50*/;
  }
}
