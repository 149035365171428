/* SECTION: Base Utilities*/
@layer utilities {
  /*ANCHOR: Style Resets*/
  .all-initial {
    all: initial;
  }

  .all-inherit {
    all: inherit;
  }

  .all-revert {
    all: revert;
  }

  .all-unset {
    all: unset;
  }

  /* TODO: STYLING This should remove the arrows from the number field when added.
  It does not. However, the added margin does make it not so bad on Chrome.*/
  .appearance-textfield {
    appearance: textfield;
  }
  .appearance-textfield::-webkit-outer-spin-button,
  .appearance-textfield::-webkit-inner-spin-button {
    @apply ml-4.5;

    appearance: textfield;
  }
}
/* !SECTION */

/*SECTION: Base Styles*/
@layer base {
  /*ANCHOR: Base Elements*/
  * {
    @apply !ring-0 !ring-transparent focus:!outline-none focus:!ring-0 focus:!ring-transparent focus-visible:!ring-0 focus-visible:!ring-transparent focus-visible:!outline-none;
  }

  html {
    @apply w-full h-full;
  }

  body {
    @apply w-full h-full font-primary m-0 p-0;
  }

  /*This is needed because nextjs adds a top-level div with id of __next that we have
  no control over. In order to style it, this is the only way afaik.*/
  #__next {
    @apply w-full h-full m-0 p-0;
  }

  /*ANCHOR: Interactable*/
  a {
    @apply underline decoration-society-550 text-society-550 hover:text-society-500 hover:decoration-society-500 hover:cursor-pointer;
  }

  /*ANCHOR: Typography*/
  p {
    @apply font-primary text-base leading-normal;
  }

  h1 {
    @apply font-title text-3xl leading-tight font-bold;
  }

  h2 {
    @apply font-title text-2xl leading-snug font-bold;
  }

  h3 {
    @apply font-title text-xl leading-relaxed font-semibold;
  }

  h4 {
    @apply font-title text-lg leading-normal font-medium;
  }

  h5 {
    @apply font-title text-base leading-normal font-normal;
  }

  h6 {
    @apply font-title text-sm leading-relaxed font-normal;
  }

  /*ANCHOR: Inputs*/
  label {
    @apply text-sm text-neutral-mid;
  }

  /*TODO: "date" inputs will not style the default text as placeholder and have issues with
  styling in general. Using javascript, we should be able to style them, but it may not be possible
  with css directly.*/
  input[type="button"],
  input[type="color"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="file"],
  input[type="hidden"],
  input[type="image"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="radio"],
  input[type="range"],
  input[type="reset"],
  input[type="search"],
  input[type="submit"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  textarea {
    @apply input-light;
  }

  input[type="checkbox"] {
    @apply input-light ring-0 cursor-pointer text-primary focus:ring-0;
  }
}
/* !SECTION */
